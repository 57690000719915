import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Card, Button, Table } from "react-bootstrap";
import pendingPaymentlist from "../../../../Json/pending-payment.json";
import PaymentView from "./payment-view";

export default function FeesReceivables() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [showPaymentView, setShowPaymentView] = useState(false);
    const handlePaymentView = (state) => {
        setShowPaymentView(state);
    }

    return (
        <React.Fragment>
            <PaymentView show={showPaymentView} closeFunction={handlePaymentView} />
            <Row className="g-3 mb-4">
                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one bg-danger text-white">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Total Receivables</Card.Title>
                            <h3 className="card-value text-white"><i className="ri-money-dollar-box-line text-white"></i> ₹ 4225</h3>
                            <small className="text-white">4 student have pendding fees.</small>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Fees Collected (this month)</Card.Title>
                            <h3 className="card-value"><i className="ri-money-dollar-box-line"></i> ₹ 0</h3>
                            <small>0 student paid so far</small>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Fees Collected (last month)</Card.Title>
                            <h3 className="card-value"><i className="ri-money-dollar-box-line"></i> ₹ 2354</h3>
                            <small>15 student paid in jan 2024</small>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Total Fees (acadamic year)</Card.Title>
                            <h3 className="card-value"><i className="ri-money-dollar-box-line"></i> ₹ 24.3 lakhs</h3>
                            <small>400 Student for current year</small>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Card className="card-one">
                <Card.Body>
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <div>
                            <h4 className="main-title fs-14 mb-0">List of Pending Payments</h4>
                        </div>
                        <div>
                            <div className="form-search me-auto">
                                <i className="ri-search-line"></i>
                                <input type="text" className="form-control" placeholder="Search for Payment Advance" />
                            </div>
                        </div>
                    </div>

                    <Table className="mb-0" responsive>
                        <thead>
                            <tr>
                                <th>Invoice ID</th>
                                <th>Student Name</th>
                                <th>Grade</th>
                                <th>Bill Amount</th>
                                <th>Bill Period</th>
                                <th>Generated On</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pendingPaymentlist?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.invoiceId}</td>
                                    <td>{item.studentName}</td>
                                    <td className="fw-bold">{item.grade}</td>
                                    <td className="fw-bold">{item.billAmt}</td>
                                    <td>{item.billPriod}</td>
                                    <td>
                                        <h6 className="mb-0">{item.date}</h6>
                                        <span className="fs-12 text-dark">{item.time}</span>
                                    </td>

                                    <td className="w-0">
                                        <div className="d-flex justify-content-end">
                                            <Button variant="secondary" className="btn-icon me-2" onClick={()=>handlePaymentView(true)}><i className="ri-eye-line"></i></Button>
                                            <Button variant="outline-dark" className="btn-icon"><i className="ri-printer-line"></i></Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}