import React from "react";
import { Table, Badge } from "react-bootstrap";
import pendingInvoicelist from "../../../../Json/feesReceivbles/pending-invoice.json";

export default function PendingInvoice() {
    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
              <div>
                <h4 className="main-title fs-14 mb-0">List of Pending Invoice</h4>
              </div>
              <div>
                <div className="custom-search-input form-search me-auto">
                  <i className="ri-search-line"></i>
                  <input type="text" className="form-control" placeholder="Search for tax group" />
                </div>
              </div>
            </div>
            <Table className="mb-0" responsive>
                <thead>
                    <tr>
                        <th>Invoice ID</th>
                        <th>Bill Period</th>
                        <th>Bill Amount</th>
                        <th>Payment</th>
                        <th>Generated On</th>
                    </tr>
                </thead>

                <tbody>
                    {pendingInvoicelist?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.invoice}</td>
                            <td className="fw-bold">{item.billPerioid}</td>
                            <td className="fw-bold">{item.billAmt}</td>
                            <td>
                            <Badge bg={`${item.payment === "Paid" ? "success":"danger"}`} className="fs-14 fw-semobold wt-80 text-uppercase round" pill>
                                {item.payment === "Unpaid" ? "Unpaid":"Paid"}
                            </Badge>
                            </td>
                            <td>
                                <h6 className="mb-0">{item.date}</h6>
                                <span className="fs-12 text-dark">{item.time}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}