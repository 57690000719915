import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Tab, Nav, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import FeesReceivables from "./components/feesReceivbles/fees-receivables";

export default function PaymentsManagement() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);


    useEffect(() => {
        document.body.classList.add('page-app');
        return () => {
            document.body.classList.remove('page-app');
        }
    }, []);

    // toggle sidebar in mobile
    // toggle sidebar in mobile
    const [isSidebarShow, setSidebarShow] = useState(false);
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Tab.Container id="left-tabs-example" defaultActiveKey="fees_receivables_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <div className="p-2 pt-0 pb-0 mb-3 mt-3">
                            <Button variant="primary" className="w-100">
                            <i className="ri-bank-card-line align-middle"></i> <span className="align-middle">Collect Fee</span>
                        </Button>
                        </div>
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link className="position-relative" eventKey="fees_receivables_tab"><i className="ri-add-box-line align-middle"></i> Fees Receivables</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="fee_payment_history_tab"><i className="ri-add-box-line align-middle"></i> Fee Payment Histroy</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="expense_tracker_tab"><i className="ri-add-box-line align-middle"></i> Expense Tracker</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="payroll_processing_tab"><i className="ri-add-box-line align-middle"></i> Payroll Processing</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="vendor_payment_tab"><i className="ri-add-box-line align-middle"></i> Vendor Payments</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="financial_audits_tab"><i className="ri-add-box-line align-middle"></i> Financial Audits</Nav.Link></Nav.Item>

                            <hr />
                            <Nav.Item><Nav.Link className="position-relative" eventKey="fee_structure_tab"><i className="ri-add-box-line align-middle"></i> Fee Structure</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="scholarships_aids_tab"><i className="ri-add-box-line align-middle"></i> Scholarship & Aids</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <Tab.Content>
                            {/* SALARY COMPOENT */}
                            <Tab.Pane eventKey="fees_receivables_tab">
                                <FeesReceivables />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fee_payment_history_tab">2</Tab.Pane>
                            <Tab.Pane eventKey="expense_tracker_tab">3</Tab.Pane>
                            <Tab.Pane eventKey="payroll_processing_tab">4</Tab.Pane>
                            <Tab.Pane eventKey="vendor_payment_tab">5</Tab.Pane>
                            <Tab.Pane eventKey="financial_audits_tab">6</Tab.Pane>

                            
                            <Tab.Pane eventKey="fee_structure_tab">7</Tab.Pane>
                            <Tab.Pane eventKey="scholarships_aids_tab">8</Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}