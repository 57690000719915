import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Image, Offcanvas, Tabs, Tab } from "react-bootstrap";
import noImg from "../../../../assets/img/no-image-found.png";
import "./payment-view.scss";
import PendingInvoice from "./pending-invoice";
import PaymentHistory from "./payment-history";


export default function PaymentView(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [activeTab, setActiveTab] = useState("pending_invoice_tab");
    const handleTabSelect = (tab) => {
      setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-50">
                <Offcanvas.Header closeButton style={{ borderBottom: "1px solid #ccc" }}>
                    <Offcanvas.Title>Payments</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row className="mb-4">
                        <Col xl={12}>
                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <Image src={noImg} width={100} />
                                </div>
                                <div className="w-100">
                                    <h2 className="fs-16 fw-semibold mb-2">Tarun Shetty</h2>
                                    <div className="d-flex gap-3 mt-3 mt-md-0 align-items-center">
                                        <h4 className="fs-12 border-left-after">PC: +91 9987885410</h4>
                                        <h4 className="fs-12 border-left-after">PC2: +91 8895601477</h4>
                                        <h4 className="fs-12">tarunShettyStudent@gmail.com</h4>
                                    </div>

                                    <Row className="mt-3">
                                        <Col xs={12} xl={3} className="position-relative">
                                            <h3 className="text-danger fs-16 fw-semibold mb-1">₹ 12300 (2)</h3>
                                            <h6 className="fs-12 text-black-50">Total Pending</h6>
                                        </Col>
                                        <Col xs={12} xl={3} className="position-relative">
                                            <h3 className="fs-16 fw-semibold mb-1">₹ 4000</h3>
                                            <h6 className="fs-12">Last Payment</h6>
                                        </Col>

                                        <Col xs={12} xl={3} className="position-relative">
                                            <h3 className="fs-16 fw-semibold mb-1">92 <small className="fs-12">days ago</small></h3>
                                            <h6 className="fs-12">Last Paid</h6>
                                        </Col>

                                        {/*  <Col xs={12} xl={3} className="position-relative">
                                            <h3 className="fs-16 fw-semibold mb-1">₹ 1500</h3>
                                            <h6 className="fs-12">Creadit Limit</h6>
                                        </Col> */}
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-custom-tabs">
                        {/* PENDING INVOICE TAB CONTENT */}
                        <Tab eventKey="pending_invoice_tab" 
                        title={<>Pending Invoice <span className="badge rounded-pill badge-length-web">4</span></>} 
                        tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3">
                                <PendingInvoice />
                            </div>
                        </Tab>

                          {/* PAYMENT HISTORY TAB CONTENT */}
                        <Tab eventKey="payment_history_tab" 
                        title={<>Payment History <span className="badge rounded-pill badge-length-web">2</span></>} 
                        tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3">
                                <PaymentHistory />
                            </div>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}