import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import ReactApexChart from "react-apexcharts";
import { Alert, Row, Col, Card, Image, Nav, ProgressBar, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import readingIcon from "../../assets/img/icons/reading.png";
import teachingIcon from "../../assets/img/icons/teaching.png";
import sadFaceIcon from "../../assets/img/icons/sad-face.png";

export default function Dashboard() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);


    useEffect(() => {
        document.body.classList.add('page-app');
        return () => {
            document.body.classList.remove('page-app');
        }
    }, []);

    const states = {
        hover: {
            filter: {
                type: "none",
            },
        },
        active: {
            filter: {
                type: "none",
            },
        },
    };

    const seriesFour = [{
        data: [[0, 1000], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }, {
        data: [[0, 30], [1, 30], [2, 30], [3, 30], [4, 30], [5, 30], [6, 30], [7, 30], [8, 30], [9, 30], [10, 30], [11, 30], [12, 30], [13, 30], [14, 30], [15, 30], [16, 30], [17, 30], [18, 30], [19, 30], [20, 30], [21, 30], [22, 30], [23, 30], [24, 30], [25, 30], [26, 30], [27, 30], [28, 30], [29, 30], [30, 30]]
    }, {
        data: [[0, 800], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }];

    const optionFour = {
        chart: {
            parentHeightOffset: 0,
            stacked: true,
            toolbar: { show: false }
        },
        grid: {
            borderColor: 'rgba(72,94,144, 0.07)',
            padding: {
                top: -20,
                left: 5
            }
        },
        states: states,
        colors: ['#506fd9', '#fff', '#85b6fe'],
        plotOptions: {
            bar: { columnWidth: '35%' },
        },
        stroke: {
            curve: 'straight',
            lineCap: 'square',
            width: 0
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 6,
            decimalsInFloat: 0,
            labels: {
                style: {
                    fontSize: '11px'
                }
            }
        },
        yaxis: {
            max: 4000,
            tickAmount: 8,
            labels: {
                style: {
                    colors: ['#a2abb5'],
                    fontSize: '11px'
                }
            }
        },
        dataLabels: { enabled: false },
        tooltip: { enabled: false },
        fill: { opacity: 1 },
        legend: { show: false }
    };

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app manage-item-page p-3 p-lg-4">
                <div className="w-100 mb-3">
                    <Alert variant="danger" className="mb-0 w-100 text-dark">
                        <i className="ri-error-warning-line align-middle" style={{ color: "#404003" }}></i> <b>ATTENTION!!</b> -
                        <span className="fw-semibold ms-1 fs-14">Generate the salary statement for the month of December 2024</span>
                        <Link className="text-dark position-absolute right-10 fw-bold">GENERATE</Link>
                    </Alert>
                </div>

                <Row className="g-3 mb-4">
                    {[
                        {
                            icon: readingIcon,
                            label: "Total Students",
                            value: 342,
                            percent: "5%",
                            des: "Than yesterday",
                        },
                        {
                            icon: teachingIcon,
                            value: 25,
                            label: "Total Teaching Staff",
                            des: "4 years of avg. tenure",
                        },
                        {
                            icon: teachingIcon,
                            label: "Total Non Teaching Staff",
                            value: 10,
                            des: "4 years of avg. tenure",
                        },
                        {
                            icon: sadFaceIcon,
                            value: 4,
                            label: "Complaints & Grievances",
                            percent: "40%",
                            status: "up",
                            des: "Higher than last month",
                        },
                        {
                            icon: teachingIcon,
                            value: 4,
                            label: "Complaints & Grievances",
                            percent: "40%",
                            status: "up",
                            des: "Higher than last month",
                        },
                    ].map((card, index) => (
                        <Col key={index}>
                            <Card className="card-one">
                                <Card.Body>
                                    <Card.Title as="label" className="fs-sm fw-medium mb-1">
                                        <Image src={card.icon} className="wt-40" alt="icon" />
                                    </Card.Title>
                                    <h3 className="card-value fw-bold fs-32 mb-1">
                                        {card.value}
                                    </h3>
                                    <h5 className="fs-14"> {card.label}</h5>
                                    <small>
                                        <span className={"d-inline-flex text-" + (card.status === "up" ? "success" : "danger")}>
                                            {card.percent}
                                            <span className={`${card.des == "Total Teaching Staff" ? "d-none" : ""}`}>
                                                {card.label == 'Total Non Teaching Staff' || card.label == 'Total Teaching Staff' ? (
                                                    <></>
                                                ):(
                                                    <>
                                                        <i className={"ri-arrow-" + (card.status === "up" ? "up" : "down") + "-line"}></i>
                                                    </>
                                                )}

                                            </span>
                                        </span>
                                        {card.des}
                                    </small>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                <Row className="g-3">

                    <Col sm={12} md={8} xl={7}>
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Day wise Student Attendance</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href="">
                                        <i className="ri-refresh-line"></i>
                                    </Nav.Link>
                                    <Nav.Link href="">
                                        <i className="ri-more-2-fill"></i>
                                    </Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                <ReactApexChart series={seriesFour} options={optionFour} type="bar" height={300} className="apex-chart-nine" />
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col sm={12} md={4} xl={5}>
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Overall Attendance Status</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <h2 className="performance-value mb-0">9.8 <small className="text-success d-flex align-items-center"><i className="ri-arrow-up-line"></i> 2.8%</small></h2>

                                <label className="card-title fs-sm fw-medium">Performance Score</label>

                                <ProgressBar className="progress-one ht-8 mt-2 mb-4">
                                    <ProgressBar now={50} />
                                    <ProgressBar now={25} variant="success" />
                                    <ProgressBar now={5} variant="orange" />
                                    <ProgressBar now={5} variant="pink" />
                                    <ProgressBar now={10} variant="info" />
                                    <ProgressBar now={5} variant="indigo" />
                                </ProgressBar>

                                <Table className="table-three">
                                    <tbody>
                                        {[
                                            {
                                                "dot": "primary",
                                                "label": "Grade 10",
                                                "count": "3,007",
                                                "percent": "50"
                                            }, {
                                                "dot": "success",
                                                "label": "Grade 9",
                                                "count": "1,674",
                                                "percent": "25"
                                            }, {
                                                "dot": "orange",
                                                "label": "Grade 8",
                                                "count": "125",
                                                "percent": "6"
                                            }, {
                                                "dot": "pink",
                                                "label": "Grade 7",
                                                "count": "98",
                                                "percent": "5"
                                            }, {
                                                "dot": "info",
                                                "label": "Grade 6",
                                                "count": "512",
                                                "percent": "10"
                                            }
                                        ].map((item, index) => (
                                            <tr key={index}>
                                                <td className="w-0"><div className={"badge-dot bg-" + item.dot}></div></td>
                                                <td>{item.label}</td>
                                                <td>{item.count}</td>
                                                <td>{item.percent}%</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}