import React from "react";
import classRoombg from "../../assets/img/account/class-bg.jpg";
import logo from "../../assets/img/logo.png";
import "./login.scss";
import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";

export default function Signin2() {
    return (
        <div className="page-sign d-block py-0">
            <Row className="g-0">
                <Col md="7" lg="5" xl="4" className="col-wrapper">
                    <Card className="card-sign">
                        <Card.Header>
                            <Link to="/" className="header-logo d-flex align-items-center mb-5">
                                <Image className="wt-70" src={logo} alt="logo" />
                                <h6 className="fs-18">Campus Connect</h6>
                            </Link>
                            <Card.Title>Sign In</Card.Title>
                            <Card.Text>Welcome back! Please signin to continue.</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Form method="get" action="/">
                                <div className="mb-4">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your email address" value="enayath.sayyed@automatebuddy.com" />
                                </div>
                                <div className="mb-4">
                                    <Form.Label className="d-flex justify-content-between">
                                        Password <Link to="/account/forgot-password">Forgot password?</Link>
                                    </Form.Label>
                                    <Form.Control type="password" placeholder="Enter your password" value="Pass@1234" />
                                </div>
                                <Button type="submit" className="btn-sign">Sign In</Button>

                                <div className="divider"><span>or sign in with</span></div>

                                <Row className="gx-2">
                                    <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
                                    <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
                                </Row>
                            </Form>
                        </Card.Body>
                        <Card.Footer>
                            Don't have an account? <Link to="/account/sign-up">Create an Account</Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className="d-none d-lg-block position-relative bg-lear-color">
                    <div className="account-logo">
                        <Image src={logo} alt="logo" />
                    </div>
                    <Image src={classRoombg} className="account-right-backround" alt="backgroud image" />
                </Col>
            </Row>
        </div>
    )
}