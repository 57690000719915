import React from "react";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import classRoombg from "../../assets/img/account/class-bg.jpg";
import logo from "../../assets/img/logo.png";

export default function SignupComponent() {
    return (
        <div className="page-sign d-block py-0">
            <Row className="g-0">
                <Col md="7" lg="5" xl="4" className="col-wrapper">
                    <Card className="card-sign">
                        <Card.Header>
                            <Link to="/" className="header-logo d-flex align-items-center mb-5">
                                <Image className="wt-70" src={logo} alt="logo" />
                                <h6 className="fs-18">Campus Connect</h6>
                            </Link>
                            <Card.Title>Sign Up</Card.Title>
                            <Card.Text>It's free to signup and only takes a minute.</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <div className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="text" placeholder="Enter your email address" />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter your password" />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Full name</Form.Label>
                                <Form.Control type="text" placeholder="Enter your full name" />
                            </div>
                            <div className="mb-4">
                                <small>By clicking <strong>Create Account</strong> below, you agree to our terms of service and privacy statement.</small>
                            </div>
                            
                            <Button variant="primary" className="btn-sign">Create Account</Button>

                            <div className="divider"><span>or sign up using</span></div>

                            <Row className="gx-2">
                                <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
                                <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            Already have an account? <Link to="/account/login">Sign In</Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col className="d-none d-lg-block position-relative bg-lear-color">
                    <div className="account-logo">
                        <Image src={logo} alt="logo" />
                    </div>
                    <Image src={classRoombg} className="account-right-backround" alt="backgroud image" />
                </Col>
            </Row>
        </div>
    );
}